<script setup lang="ts">
import MY_USER_INFO_QUERY from '~/graphql/queries/myUserInfo.gql'

const userStore = useUserStore()
const { initLanguage, setLanguage } = useLanguage()
const { logout } = useAuth()

useHead(() => ({
  bodyAttrs: {
    'data-theme': userStore.theme === 'system' ? undefined : userStore.theme,
  },
}))

onBeforeMount(() => {
  initLanguage()
})

const { data, error, status } = useAsyncQuery<any>(MY_USER_INFO_QUERY)
watch(status, () => {
  if (data.value) {
    userStore.setUserFromResponse(data.value.myUser)
    userStore.setSettingsFromResponse(data.value.myUserSettings?.userSettings)
    setLanguage(userStore.language)
  } else if (error.value) {
    logout()
  }
})
</script>

<template>
  <div class="flex h-full">
    <div
      v-if="status === 'pending'"
      class="center w-screen"
    >
      {{ $t('loading') }}
    </div>
    <Transition
      name="navbar-slide-in"
    >
      <Navbar v-show="data" />
    </Transition>
    <Transition
      name="page-fade"
    >
      <main
        v-show="data"
        class="flex-1 overflow-y-auto p-6 full-hd:px-16 wqhd:px-48 uhd:px-96"
      >
        <slot />
      </main>
    </Transition>
  </div>
</template>

<style scoped>
.navbar-slide-in-enter-active {
  transition:
    transform 0.6s ease-out,
    opacity 0.8s ease-out;
}
.navbar-slide-in-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
