<script setup lang="ts">
import { Cog8ToothIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
})
const userStore = useUserStore()
const isSettingsDialogVisible = ref(false)
function showSettingsDialog() {
  isSettingsDialogVisible.value = true
}
function hideSettingsDialog() {
  isSettingsDialogVisible.value = false
}
</script>

<template>
  <div>
    <div
      v-if="props.isOpen"
      class="flex items-center space-x-3 p-4"
    >
      <NavbarProfileDropdown />
      <div class="flex flex-1 flex-col justify-center space-y-1">
        <p class="text-base">
          {{ userStore.user?.username }}
        </p>
        <span class="text-xs leading-4">
          {{ userStore.user?.email }}
        </span>
      </div>
      <button
        type="button"
        class="rounded-md px-3 py-2 hover:bg-navbar-user-hover"
        @click="showSettingsDialog"
      >
        <Cog8ToothIcon class="size-8" />
      </button>
    </div>

    <div
      v-else
      class="flex flex-col items-center space-y-2 py-4"
    >
      <button
        type="button"
        class="rounded-md px-3 py-2 hover:bg-navbar-user-hover"
        @click="showSettingsDialog"
      >
        <Cog8ToothIcon class="size-8" />
      </button>
      <NavbarProfileDropdown />
    </div>

    <SettingsDialog
      v-if="isSettingsDialogVisible"
      @closed="hideSettingsDialog"
    />
  </div>
</template>
