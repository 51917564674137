import ToolIcon from '~/assets/svg/navbar/tool.svg'
import ChecklistIcon from '~/assets/svg/navbar/checklist.svg'
import MachineIcon from '~/assets/svg/navbar/machine.svg'
import {
  Outline24BeakerIcon,
  Outline24CubeIcon,
  Outline24DocumentIcon,
  Outline24HomeIcon,
  Outline24ListBulletIcon,
  Outline24UserGroupIcon,
  Outline24UsersIcon,
  Outline24WrenchScrewdriverIcon,
} from '#components'

export function useMenuItems() {
  const unfinishedTasksStore = useUnfinishedTasksStore()

  const menuItems = computed(() => {
    const items = [
      {
        key: 'dashboard',
        path: '/',
        icon: Outline24HomeIcon,
        count: unfinishedTasksStore.totalUnfinishedTasks,
      },
      {
        key: 'parts',
        path: '/production/parts/',
        icon: Outline24CubeIcon,
      },
      {
        key: 'documentHeads',
        path: '/production/document-heads/',
        icon: Outline24DocumentIcon,
      },
      {
        key: 'users',
        path: '/core-data/users/',
        icon: Outline24UsersIcon,
      },
      {
        key: 'groups',
        path: '/core-data/groups/',
        icon: Outline24UserGroupIcon,
      },
      {
        key: 'machines',
        path: '/core-data/machines/',
        icon: MachineIcon,
      },
      {
        key: 'statuses',
        path: '/core-data/statuses/',
        icon: Outline24ListBulletIcon,
      },
      {
        key: 'schema',
        path: '/converting/schema/',
        icon: Outline24WrenchScrewdriverIcon,
      },
      {
        key: 'mappingTable',
        path: '/converting/mapping-table/',
        icon: Outline24WrenchScrewdriverIcon,
      },
      {
        key: 'realtools',
        path: '/tm/',
        icon: ToolIcon,
      },
      {
        key: 'orders',
        path: '/tm/orders/',
        icon: ChecklistIcon,
      },
    ]
    if (import.meta.env.DEV)
      items.push({
        key: 'development',
        path: '/development/',
        icon: Outline24BeakerIcon,
      })

    return items
  })

  return {
    menuItems,
  }
}
