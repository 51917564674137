<script setup lang="ts">
import { useStorage } from '@vueuse/core'
import RwtLogoInvert from '~/assets/svg/logos/rwt-logo-invert.svg'
import RwtLogoCube from '~/assets/svg/logos/rwt-logo-cube.svg'

const isNavbarOpen = useStorage('navbar-open-flag', true)
const toggleSidebar = () => (isNavbarOpen.value = !isNavbarOpen.value)
</script>

<template>
  <div
    class="flex flex-col bg-navbar text-white transition-[width] duration-500"
    :class="{ 'w-80': isNavbarOpen, 'w-26': !isNavbarOpen }"
  >
    <!-- Logo -->
    <div class="flex h-16 items-center justify-center border-b border-navbar">
      <RwtLogoInvert
        v-if="isNavbarOpen"
        class="h-8"
      />
      <RwtLogoCube
        v-else
        class="h-8"
      />
    </div>

    <!-- Menu -->
    <nav class="flex flex-1 flex-col items-center overflow-y-auto py-4">
      <NavbarItems
        :is-navbar-open="isNavbarOpen"
      />
    </nav>
    <!-- Footer -->
    <div class="flex flex-col">
      <button
        type="button"
        class="flex justify-center rounded border-t border-navbar px-4 py-2 hover:bg-navbar-hover hover:text-white"
        @click="toggleSidebar"
      >
        <Outline24ChevronDoubleLeftIcon
          class="size-6 text-gray-300 duration-300"
          :class="{ 'rotate-180 duration-300': !isNavbarOpen }"
        />
      </button>

      <NavbarFooter
        :is-open="isNavbarOpen"
        class="bg-navbar-user text-navbar-user"
      />
    </div>
  </div>
</template>
