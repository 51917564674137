<script setup lang="ts">
import { useMenuItems } from './navbar-menus'

const props = defineProps({
  isNavbarOpen: {
    type: Boolean,
    required: true,
  },
})

const { menuItems } = useMenuItems()
</script>

<template>
  <div
    class="space-y-2"
    :class="props.isNavbarOpen ? 'w-full px-4' : ''"
  >
    <NuxtLink
      v-for="menu in menuItems"
      :key="menu.key"
      v-slot="{ href, navigate, isActive, isExactActive }"
      :to="menu.path"
      custom
    >
      <a
        :href="href"
        class="relative flex items-center space-x-2 rounded-2xl p-4 text-sm font-medium text-navbar"
        :class="[
          isActive && 'text-navbar hover:bg-navbar-hover hover:text-navbar',
          isExactActive
            && 'bg-navbar-active text-navbar-active hover:bg-navbar-hover',
          isActive && href !== '/' && href !== '/de'
            ? 'bg-navbar-active text-navbar-active hover:bg-navbar-hover'
            : 'text-navbar hover:bg-navbar-hover hover:text-navbar',
        ]"
        @click="navigate"
      >
        <component
          :is="menu.icon"
          v-if="menu.icon"
          class="size-6 shrink-0"
          aria-hidden="true"
        />
        <span
          v-if="isNavbarOpen"
          class="text-nowrap"
        >
          {{ $t(menu.key) }}
        </span>
        <span
          v-if="menu.count"
          class="absolute flex items-center justify-center bg-[#D04467] font-normal text-white"
          :class="[
            isNavbarOpen
              ? 'right-6 min-w-[48px] rounded-2xl px-2 py-1.5'
              : 'bottom-0 right-0 min-w-[24px] rounded-full px-1.5',
          ]"
        >{{ menu.count }}</span>
      </a>
    </NuxtLink>
  </div>
</template>
