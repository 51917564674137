<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { UserCircleIcon } from '@heroicons/vue/24/outline'

const { t } = useI18n()
const { logout } = useAuth()
</script>

<template>
  <Menu
    as="div"
    class="relative h-12"
  >
    <MenuButton>
      <UserCircleIcon class="size-12 rounded-full" />
    </MenuButton>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute bottom-2 left-14 z-30 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <!-- Logout button -->
          <MenuItem v-slot="{ active }">
            <button
              type="button"
              class="flex w-full px-4 py-2 text-sm text-gray-700"
              :class="[active ? 'bg-[#ebf4fa]' : '']"
              @click="logout"
            >
              {{ t("signOut") }}
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
